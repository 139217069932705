import { useMediaQuery } from "@mui/material";
import React, { Fragment, useState } from "react";
import { useLocation } from "react-router-dom";
import Circle from "../../components/Circle";
import NavbarList from "../../components/NavbarList";
import ResponsiveNavbar from "../../components/ResponsiveNavbar";
import * as S from "./styles";

const Layout = ({ children }) => {
  const isResponsive = useMediaQuery("(min-width:950px)");

  const [isActive, setIsActive] = useState(false);

  const location = useLocation();

  const isPageNotFound = location.pathname === "/404";
  const isPageHomePage = location.pathname === "/";

  const circles = [
    {
      width: isResponsive ? 725.65 : 391,
      height: isResponsive ? 725.65 : 391,
      background:
        "linear-gradient(135.15deg, rgba(99, 15, 158, 0.62) 14.94%, rgba(139, 192, 255, 0.6) 86.22%)",
      left: isResponsive ? -190 : -175,
      top: isResponsive ? -190 : -190,
      style: {
        transform: "rotate(-98.47deg)",
      },
    },
    !isResponsive
      ? {
          width: 151,
          height: 151,
          background:
            "linear-gradient(161.03deg, rgba(130, 62, 165, 0.84) 14.57%, rgba(125, 190, 225, 0.6048) 86.76%, rgba(152, 212, 255, 0.84) 158.96%)",
          blur: 0,
          right: -75,
          top: 213,
          style: {
            transform: "rotate(-90deg)",
            filter: "blur(8px)",
          },
        }
      : {},
    !isResponsive
      ? {
          width: 80,
          height: 80,
          background:
            "radial-gradient(71.15% 71.15% at 14.61% 8.59%, rgba(117, 23, 211, 0.93) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(95.25% 79.03% at 18.77% 100%, #DE2DD3 0%, #9D27C5 100%)",
          blur: 0,
          right: -12,
          top: 331,
          style: {
            filter: "blur(8px)",
          },
        }
      : {},
    {
      width: isResponsive ? 240 : 160,
      height: isResponsive ? 240 : 160,
      background:
        "radial-gradient(71.15% 71.15% at 14.61% 8.59%, rgba(117, 23, 211, 0.93) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(95.25% 79.03% at 18.77% 100%, #DE2DD3 0%, #9D27C5 100%)",
      right: 0,
      top: isResponsive ? 872 : 907,
      style: { filter: isResponsive ? "blur(22px)" : "blur(14px)" },
    },
    {
      width: isResponsive ? 435.65 : 184.22,
      height: isResponsive ? 435.65 : 184.22,
      background:
        "linear-gradient(161.03deg, rgba(130, 62, 165, 0.84) 14.57%, rgba(125, 190, 225, 0.6048) 86.76%, rgba(152, 212, 255, 0.84) 158.96%)",
      left: isResponsive ? -92 : -90,
      top: isResponsive ? 1055 : 1055,
      style: {
        filter: isResponsive ? "blur(22px)" : "blur(5px)",
        transform: isResponsive ? "rotate(-90deg)" : "rotate(175deg)",
      },
    },
    {
      width: isResponsive ? 745.65 : 197,
      height: isResponsive ? 745.65 : 197,
      background: "#7517D3",
      right: isResponsive ? -372 : -97,
      bottom: isResponsive ? 338 : 134,
      style: {
        filter: isResponsive ? "blur(22px)" : "blur(7px)",
        opacity: 0.7,
        zIndex: 5,
      },
    },
    {
      width: isResponsive ? 317.65 : 74,
      height: isResponsive ? 317.65 : 74,
      background:
        "linear-gradient(135.15deg, rgba(81, 12, 130, 0.41) 14.94%, rgba(105, 178, 231, 0.57) 86.22%)",
      right: isResponsive ? 252 : 23,
      bottom: isResponsive ? 714 : 117,
      style: {
        filter: isResponsive ? "blur(12px)" : "blur(3px)",
        transform: "rotate(175deg)",
      },
    },
    !isResponsive
      ? {
          width: 183.65,
          height: 183.65,
          background:
            "linear-gradient(161.03deg, rgba(130, 62, 165, 0.84) 14.57%, rgba(125, 190, 225, 0.6048) 86.76%, rgba(152, 212, 255, 0.84) 158.96%)",
          left: -90,
          top: 530,
          style: {
            filter: "blur(20px)",
            zIndex: 5,
            transform: "rotate(-90deg)",
          },
        }
      : {},
    isResponsive
      ? {
          width: 607.65,
          height: 745.65,
          background:
            "linear-gradient(135.15deg, rgba(81, 12, 130, 0.41) 14.94%, rgba(105, 178, 231, 0.57) 86.22%)",
          left: -303,
          bottom: 100,
          style: {
            filter: "blur(20px)",
            zIndex: 5,
            transform: "rotate(175deg)",
          },
        }
      : {},
    isResponsive
      ? {
          width: 380.65,
          height: 380.65,
          background:
            "radial-gradient(71.15% 71.15% at 14.61% 8.59%, rgba(117, 23, 211, 0.93) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(95.25% 79.03% at 18.77% 100%, #DE2DD3 0%, #9D27C5 100%)",
          left: -185,
          bottom: 0,
          style: {
            filter: "blur(30px)",
            zIndex: 5,
            opacity: 0.7,
            transform: "matrix(1, 0, 0, -1, 0, 0)",
          },
        }
      : {},
  ];

  const toggelNavbar = () => {
    document.body.style.overflow = !isActive ? "hidden" : "";
    setIsActive(!isActive);
  };

  const circlesSlices = !isPageHomePage ? circles.slice(0, 5) : circles;

  return (
    <Fragment>
      {isPageNotFound ? (
        children
      ) : (
        <S.Layout>
          {/* <ResponsiveNavbar setIsActive={toggelNavbar} isActive={isActive} /> */}
          <S.Navbar>
            <NavbarList setIsActive={toggelNavbar} />
          </S.Navbar>
          {circlesSlices
            .filter((n) => Object.keys(n).length)
            .map((circle, index) => (
              <Circle
                key={index}
                width={circle.width}
                height={circle.height}
                background={circle.background}
                blur={circle.blur}
                left={circle?.left}
                right={circle?.right}
                bottom={circle?.bottom}
                top={circle?.top}
                style={circle.style}
              />
            ))}
          <S.Content>{children}</S.Content>
        </S.Layout>
      )}
    </Fragment>
  );
};

export default Layout;
