import { Link } from "react-router-dom";
import styled from "styled-components";

export const NavbarList = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 1418px;
`;

export const Image = styled.img`
  max-width: 240px;
  cursor: pointer;

  @media screen and (max-width: 950px) {
    max-width: 150px;
  }
`;

export const LinkHref = styled(Link)`
  font-weight: 800;
  font-size: 18px;
  color: ${({ active }) => (active ? "#67219B" : "#fff")};
  cursor: pointer;
  text-decoration: none;

  &.margin {
    margin: 0 50px;

    @media screen and (max-width: 600px) {
      margin-right: 0;
    }
  }

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }

  @media screen and (max-width: 430px) {
    display: none;
  }
`;

export const TextContainer = styled.div`
  display: flex;
  align-items: center;

  @media screen and (max-width: 950px) {
    svg {
      width: 50px;
    }
  }
`;

export const HamburgerTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DarkMode = styled.div`
  font-weight: 700;
  font-size: 14.9954px;
  color: #1e1e1e;
  margin-left: 12px;
`;
