import styled from "styled-components";

export const DeveloperTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 88px;
  padding: 0 68px;
  margin-bottom: 48px;

  @media screen and (max-width: 950px) {
    padding: 0 24px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 64px;
  }
`;

export const DeveloperToolsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  max-width: 950px;
  width: 100%;
`;

export const Title = styled.div`
  color: #f8efff;
  font-weight: 800;
  font-size: 60px;
  text-align: center;

  @media screen and (max-width: 950px) {
    font-size: 38px;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

export const Text = styled.div`
  font-weight: 500;
  font-size: 28px;
  width: 100%;
  color: #28022e;
  margin-top: ${({ marginTop }) => `${marginTop}px` || 0};
  text-align: center;

  @media screen and (max-width: 950px) {
    font-size: 17px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
    margin-top: 11px;
  }
`;

export const TitleExplain = styled.div`
  font-weight: 600;
  font-size: 28px;
  color: #28022e;
  margin-top: 36px;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

export const HardTitle = styled.div`
  font-weight: bold;
  font-size: 28px;
  color: #28022e;
  margin-top: 36px;
  text-align: center;
  width: 100%;

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

export const ExplainContainer = styled.div`
  margin-top: 23px;
  width: 100%;

  @media screen and (max-width: 500px) {
    margin-top: 4px;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 24px;

  @media screen and (max-width: 830px) {
    margin-top: 0;
  }
`;

export const Button = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  background: linear-gradient(133.83deg, #8d40cc 4.61%, #5cdcee 94.48%);
  border-radius: 7px;
  color: #fff;
  padding: 0.8em 1.1em 0.7em;
  font-weight: 700;
  font-size: 1em;
  margin-right: 24px;
  max-width: 240px;
  cursor: pointer;
  text-decoration: none;

  &:last-child {
    margin-right: 0;
  }
  &:hover {
    background: linear-gradient(133.83deg,#7731b1 4.61%,#26bed3 94.48%);
  }

  @media screen and (max-width: 830px) {
    margin-right: 0;
    margin-top: 24px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
    padding: 6px 12px;
    margin-top: 18px;
    max-width: 140px;
  }
`;
