import React from "react";
import * as S from "./styles";

const DeveloperTools = () => {
  return (
    <S.DeveloperTools>
      <S.DeveloperToolsContainer>
        <S.DeveloperToolsTextContainer>
          <S.DeveloperToolsTitleMini>DEVELOPER</S.DeveloperToolsTitleMini>
          <S.DeveloperToolsTitle>DEVELOPER TOOLS</S.DeveloperToolsTitle>
          <S.DeveloperToolsText>
            The Glitter team is committed to the open-source developer
            community. We love collaborating and working together with the
            community on code optimization. As such, we’ll be continually
            updating our SDK based on community feedback. Stay tuned to this
            portal for access to more open-source materials as we continue to
            develop. We welcome your participation and ideas as we continue to
            grow!
          </S.DeveloperToolsText>
          <S.DeveloperToolsTextButton to="/developer-tools">
            GO TO DEV TOOLS
          </S.DeveloperToolsTextButton>
        </S.DeveloperToolsTextContainer>
      </S.DeveloperToolsContainer>
    </S.DeveloperTools>
  );
};

export default DeveloperTools;
