import { useMediaQuery } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import React from "react";
import * as S from "./styles";

const BackButtons = () => {
  const isWide = useMediaQuery("(min-width:1220px)");
  return (
    <S.BackButtons>
      <S.BackButtonsContainer>
        <S.ButtonContainer>
          <S.Button
            href="https://www.glitterfinance.org/"
            target="_blank"
            rel="noreferrer"
          >
            <ArrowBackIosIcon fontSize="inherit" />
            BACK TO GLITTER HOME
          </S.Button>
          <S.Button
            href="https://bridge.glitterfinance.org/"
            target="_blank"
            rel="noreferrer"
          >
            <ArrowBackIosIcon fontSize="inherit" />
            BACK TO BRIDGE
          </S.Button>
        </S.ButtonContainer>
      </S.BackButtonsContainer>
    </S.BackButtons>
  );
};

export default BackButtons;
