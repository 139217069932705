import React from "react";
import * as S from "./styles";

const Circle = ({
  width,
  height,
  background,
  top,
  left,
  right,
  bottom,
  style,
}) => {
  return (
    <S.Circle
      width={width}
      height={height}
      background={background}
      top={top}
      left={left}
      right={right}
      bottom={bottom}
      style={style}
    />
  );
};

export default Circle;
