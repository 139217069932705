import { useMediaQuery } from "@mui/material";
import React from "react";
import Circle from "../../components/Circle";
import OverviewInformation from "../../components/OverviewInformation";
import * as S from "./styles";

const OverviewSection = () => {
  const isWide = useMediaQuery("(min-width:1220px)");
  return (
    <S.OverviewSection>
      <S.OverviewContainer>
        <OverviewInformation />
      </S.OverviewContainer>
      {isWide ? (
        <S.CircleAbsolute>
          <S.CircleContainer>
            <Circle
              width={553}
              height={553}
              background={
                "linear-gradient(161.03deg, rgba(130, 62, 165, 0.84) 14.57%, rgba(125, 190, 225, 0.6048) 86.76%, rgba(152, 212, 255, 0.84) 158.96%)"
              }
              style={{ position: "relative", transform: "rotate(-90deg)" }}
            />

            <Circle
              width={296}
              height={296}
              background={
                "radial-gradient(71.15% 71.15% at 14.61% 8.59%, rgba(117, 23, 211, 0.93) 0%, rgba(0, 0, 0, 0) 100%), radial-gradient(95.25% 79.03% at 18.77% 100%, #DE2DD3 0%, #9D27C5 100%)"
              }
              left={-100}
              bottom={70}
              style={{ opacity: 0.7, filter: "blur(30px)" }}
            />
          </S.CircleContainer>
        </S.CircleAbsolute>
      ) : null}
    </S.OverviewSection>
  );
};

export default OverviewSection;
