import React from "react";
import * as S from "./styles";

const DeveloperToolsPage = () => {
  return (
    <S.DeveloperTools>
      <S.DeveloperToolsContainer>
        <S.Title>DEVELOPER TOOLS</S.Title>
        <S.Text marginTop={16}>
          Glitter Finance is a cross-chain protocol that allows multiple chains
          to pass generic interchain messages for fungible and non-fungible
          tokens.
        </S.Text>

        <S.TitleExplain>We've made it easy for Developers to:</S.TitleExplain>
        <S.ExplainContainer>
          <S.Text>1. Access the Bridge API.</S.Text>
          <S.Text>
            2. Integrate the bridge directly into any third party applications.
          </S.Text>
        </S.ExplainContainer>

        <S.Text marginTop={14}>
          Developers looking to build on and interact with Glitter Finance
          bridge infrastructure can make use of this portal to get started in
          setting-up cross-chain accessibility via the bridge infrastructure.
        </S.Text>

        <S.HardTitle>API Calls</S.HardTitle>
        <S.Text>
          Glitter Finance API Documentation provides the guide to call the
          bridge data and pass request data to the bridge.
        </S.Text>
        <S.ButtonContainer>
          <S.Button
            href="https://api.glitterfinance.org/api-docs/"
            target="_blank"
            rel="noreferrer"
          >
            API DOCUMENTATION
          </S.Button>
        </S.ButtonContainer>

        <S.HardTitle>SDK Solution</S.HardTitle>
        <S.Text>
          Easy integration of the bridge platform into any 3rd party application
          can be done via the SDK solution. This has been made available for
          whoever wants its users to cross-chain their asset directly using
          their own application portal.
        </S.Text>
        <S.ButtonContainer>
          <S.Button
            href="https://github.com/Glitter-Finance/SDK"
            target="_blank"
            rel="noreferrer"
          >
            SDK
          </S.Button>
        </S.ButtonContainer>
      </S.DeveloperToolsContainer>
    </S.DeveloperTools>
  );
};

export default DeveloperToolsPage;
