import styled from "styled-components";

export const Overview = styled.div`
  text-align: center;
  position: relative;
  z-index: 5;
  flex: 1;
`;

export const OverviewTitle = styled.h1`
  font-weight: 800;
  text-transform: uppercase;
  color: #fff;
  font-size: 60px;

  @media screen and (max-width: 950px) {
    font-size: 38px;
  }

  @media screen and (max-width: 500px) {
    font-size: 18px;
  }
`;

export const OverviewText = styled.p`
  color: #28022e;
  font-size: 30px;
  margin-top: 13px;
  font-weight: 500;

  @media screen and (max-width: 950px) {
    font-size: 18px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

export const Pink = styled.span`
  color: #67219b;
`;
