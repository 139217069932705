import styled from "styled-components";

export const OverviewSection = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 35px;
  padding: 0 68px;
  position: relative;

  @media screen and (max-width: 1220px) {
    margin-top: 25px;
  }

  @media screen and (max-width: 950px) {
    padding: 0 15px;
  }
`;

export const OverviewContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 1000px;
  width: 100%;

  @media screen and (max-width: 1220px) {
    max-width: 100%;
  }
`;

export const CircleContainer = styled.div`
  position: relative;
`;

export const CircleAbsolute = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 68px;
`;
