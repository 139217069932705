import styled from "styled-components";

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 156px;
  position: relative;
  z-index: 5;
  margin-bottom: 190px;
  @media screen and (max-width: 950px) {
    margin-bottom: 35px;
  }
`;

export const FooterContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 0 24px;
  max-width: 523px;
`;
