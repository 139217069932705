import React from "react";
import * as S from "./styles";

const Portal = () => {
  return (
    <S.Portal>
      <S.PortalView>
        <S.PortalSmallText>OVERVIEW</S.PortalSmallText>
        <S.PortalTitle>
          What the Glitter Bridge SDK makes possible
        </S.PortalTitle>
        <S.PortalText>
          Our SDK is a fast and efficient way to become <b>interoperable</b>.
          Follow the links provided to tap into our open-source interoperability
          tools and while you’re there, please leave your feedback on how we can
          optimize them for the future.
        </S.PortalText>
      </S.PortalView>
    </S.Portal>
  );
};

export default Portal;
