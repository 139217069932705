import React from "react";
import * as S from "./styles";
import FooterSocials from "../../components/FooterSocials";

const Footer = () => {
  return (
    <S.Footer>
      <S.FooterContainer>
        <FooterSocials />
      </S.FooterContainer>
    </S.Footer>
  );
};

export default Footer;
