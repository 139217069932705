import styled from "styled-components";

export const Portal = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  margin-top: 125px;
  position: relative;
  z-index: 5;

  @media screen and (max-width: 950px) {
    margin-top: 68px;
  }
`;

export const PortalView = styled.div`
  max-width: 902px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`;

export const PortalSmallText = styled.span`
  color: #28022e;
  font-size: 24px;
  font-weight: 700;

  @media screen and (max-width: 950px) {
    font-size: 18px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
  }
`;

export const PortalTitle = styled.h1`
  font-weight: 700;
  font-size: 48px;
  color: #f8efff;
  text-transform: uppercase;
  max-width: 700px;
  margin-top: 16px;

  @media screen and (max-width: 950px) {
    font-size: 32px;
  }

  @media screen and (max-width: 500px) {
    font-size: 17px;
    margin-top: 4px;
  }
`;

export const PortalText = styled.p`
  font-weight: 500;
  font-size: 28px;
  color: #28022e;
  margin-top: 16px;

  @media screen and (max-width: 950px) {
    font-size: 17px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
    margin-top: 11px;
  }
`;
