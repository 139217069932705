import { Link } from "react-router-dom";
import styled from "styled-components";

export const DeveloperTools = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 125px;

  @media screen and (max-width: 950px) {
    margin-top: 82px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 68px;
  }
`;

export const DeveloperToolsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  max-width: 902px;
  padding: 0 24px;
`;

export const DeveloperToolsTextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: left;

  @media screen and (max-width: 950px) {
    max-width: 100%;
  }

  @media screen and (max-width: 500px) {
    align-items: center;
  }
`;

export const DeveloperToolsTitle = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #f8efff;
  margin-top: 19px;

  @media screen and (max-width: 950px) {
    font-size: 32px;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: center;
    font-size: 16px;
    margin-top: 8px;
  }
`;

export const DeveloperToolsTitleMini = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #28022e;

  @media screen and (max-width: 950px) {
    font-size: 18px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: center;
    font-size: 10px;
  }
`;

export const DeveloperToolsText = styled.p`
  font-weight: 500;
  font-size: 24px;
  color: #28022e;
  margin-top: 15px;
  text-align: center;

  @media screen and (max-width: 950px) {
    font-size: 16px;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
    text-align: center;
    font-size: 10px;
    margin-top: 11px;
  }
`;

export const DeveloperToolsTextButton = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 332px;
  width: 100%;
  padding: 15px 30px;
  background: linear-gradient(133.83deg, #8d40cc 4.61%, #5cdcee 94.48%);
  margin-top: 53px;
  color: #fff;
  font-weight: 700;
  border-radius: 51px;
  font-size: 24px;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    background: linear-gradient(133.83deg,#7731b1 4.61%,#26bed3 94.48%);
  }

  @media screen and (max-width: 950px) {
    font-size: 17px;
    max-width: 260px;
    margin-top: 23px;
  }

  @media screen and (max-width: 500px) {
    padding: 6px 12px;
    font-size: 10px;
    max-width: 125px;
  }
`;
