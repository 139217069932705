import styled from "styled-components";

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 25;
  background: rgba(255, 69, 214, 0.14);
  backdrop-filter: blur(16.5px);
  pointer-events: ${({ isActive }) => (isActive ? "all" : "none")};
  opacity: ${({ isActive }) => (isActive ? 1 : 0)};
  transition: 250ms ease;
`;

export const ResponsiveNavbar = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 35;
  background: linear-gradient(
      170.94deg,
      rgba(214, 238, 255, 0.4) 0.13%,
      rgba(255, 37, 142, 0.3) 44.68%,
      rgba(196, 106, 251, 0.31) 91.44%
    ),
    rgba(219, 219, 219, 1);
  width: 100%;
  max-width: 500px;
  padding: 22px 29px;
  transform: ${({ isActive }) =>
    isActive ? "translateX(0%)" : "translateX(100%)"};
  transition: 250ms ease;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  overflow-y: auto;

  @media screen and (max-width: 600px) {
    max-width: calc(100vw - 35px);
    padding: 22px 16px;
  }
`;

export const ResponsiveNavbarTopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 46px;
`;

export const ResponsiveNavbarSwitcher = styled.div`
  display: flex;
  align-items: center;
`;

export const ResponsiveNavbarText = styled.div`
  margin-left: 13px;
  color: #242424;
  font-weight: 800;
  font-size: 18px;

  @media screen and (max-width: 600px) {
    font-size: 14px;
  }
`;

export const Dropdown = styled.div`
  margin-bottom: 16px;
`;
