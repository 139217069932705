import React from "react";
import * as S from "./styles";
import { ReactComponent as Discord } from "../../assets/svg/discord.svg";
import { ReactComponent as Github } from "../../assets/svg/github.svg";
import { ReactComponent as LinkeDin } from "../../assets/svg/linkedin.svg";
import { ReactComponent as Instgram } from "../../assets/svg/instgram.svg";
import { ReactComponent as Telegram } from "../../assets/svg/telegram.svg";
import { ReactComponent as Twitter } from "../../assets/svg/twitter.svg";
import { ReactComponent as YouTube } from "../../assets/svg/youtube.svg";
import { useMediaQuery } from "@mui/material";

const FooterSocials = () => {
  const socials = [
    {
      component: <Github />,
      link: "https://github.com/Glitter-Finance",
    },
    {
      component: <Discord />,
      link: "https://discord.gg/wACDZGhk9D",
    },
    {
      name: "github",
      component: <LinkeDin />,
      link: "https://www.linkedin.com/company/glitter-finance",
    },
    {
      component: <Instgram />,
      link: "https://www.instagram.com/glitter.finance/",
    },
    {
      component: <Telegram />,
      link: "https://t.me/GlitterFinanceGlobal",
    },
    {
      component: <Twitter />,
      link: "https://twitter.com/GlitterFinance",
    },
    {
      component: <YouTube />,
      link: "https://www.youtube.com/channel/UCWhZs21w5_9tcd3xtlL13pA",
    },
  ];

  const isWide = useMediaQuery("(max-width:500px)");

  return (
    <S.FooterSocialView>
      {isWide ? (
        <S.FooterResponsive>
          <S.FooterSocials>
            {socials.slice(0, 3).map((social, index) => (
              <a
                href={social.link}
                key={index}
                target="_blank"
                rel="noreferrer"
              >
                {social.component}
              </a>
            ))}
          </S.FooterSocials>
          <S.FooterSocials>
            {socials.slice(3, 7).map((social, index) => (
              <a
                href={social.link}
                key={index}
                target="_blank"
                rel="noreferrer"
              >
                {social.component}
              </a>
            ))}
          </S.FooterSocials>
        </S.FooterResponsive>
      ) : (
        <S.FooterSocials>
          {socials.map((social, index) => (
            <a href={social.link} key={index} target="_blank" rel="noreferrer">
              {social.component}
            </a>
          ))}
        </S.FooterSocials>
      )}

      <S.FooterText>2022 Glitter Finance. All Rights Reserved.</S.FooterText>
    </S.FooterSocialView>
  );
};

export default FooterSocials;
