import styled from "styled-components";

export const BackButtons = styled.div`
width: 100%;
margin-top: 88px;
padding: 0 68px;
position: relative;

@media screen and (max-width: 1220px) {
  margin-top: 64px;
}

@media screen and (max-width: 950px) {
  padding: 0 26px;
}
`;

export const BackButtonsContainer = styled.div`
max-width: 1000px;
width: 100%;
margin: 0 auto;

@media screen and (max-width: 1220px) {
  max-width: 100%;
}
`;


export const ButtonContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 10px;
  align-items: flex-start;
  @media screen and (max-width: 767px) {
    gap: 5px;
  }

`;

export const Button = styled.a`
  text-align: center;
  color: #fff;
  padding: 15px 30px;
  font-weight: 700;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 51px;
  font-size: 24px;
  background: linear-gradient(133.83deg, #8d40cc 4.61%, #5cdcee 94.48%);

  &:hover {
    background: linear-gradient(133.83deg,#7731b1 4.61%,#26bed3 94.48%);
  }

  @media screen and (max-width: 767px) {
    font-size: 10px;
    padding: 6px 12px;
  }
`;