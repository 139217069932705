import React from "react";
import * as S from "./styles";
import Logo from "../../assets/images/logo.png";
import { Link, useLocation } from "react-router-dom";

const NavbarList = () => {
  const location = useLocation();
  return (
    <S.NavbarList>
      <Link style={{ textDecoration: "none" }} to="/">
        <S.Image src={Logo} alt="portal-bridge" />
      </Link>

      <S.HamburgerTextContainer>
        <S.TextContainer>
          <S.LinkHref to="/" active={location.pathname === "/"}>
            TOKENS
          </S.LinkHref>
          <S.LinkHref
            to="/developer-tools"
            active={location.pathname === "/developer-tools"}
            className="margin"
          >
            DEVELOPERS
          </S.LinkHref>
        </S.TextContainer>
      </S.HamburgerTextContainer>
    </S.NavbarList>
  );
};

export default NavbarList;
