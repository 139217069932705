import styled from "styled-components";
import { ReactComponent as Arrow } from "../../assets/svg/arrow.svg";

export const Dropdown = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const DropdownContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  filter: drop-shadow(0px 1.49954px 7.49772px rgba(0, 0, 0, 0.1));
  background: radial-gradient(
      79.22% 30.01% at 50% 103.2%,
      rgba(133, 196, 255, 0.2) 0%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(90deg, #ffffff 0%, rgba(255, 255, 255, 0) 100%);
  width: 100%;
  padding: 20px;
  border-radius: 17px;
  cursor: pointer;
  filter: drop-shadow(0px 1.49954px 7.49772px rgba(0, 0, 0, 0.1));
`;

export const DropdownText = styled.div`
  display: flex;
  align-items: center;
  color: #242424;
  font-weight: 800;
  font-size: 19.4941px;

  @media screen and (max-width: 500px) {
    font-size: 14px;
  }
`;

export const DropdownArrow = styled(Arrow)`
  transition: 350ms ease;
`;
