import React from "react";
import * as S from "./styles";

const OverviewInformation = () => {
  return (
    <S.Overview>
      <S.OverviewTitle>
        List your token and bridge it <S.Pink>cross-chain</S.Pink>, all in one
        place!
      </S.OverviewTitle>
      <S.OverviewText>
        Use our developer portal to find the tools you’ll need to list your
        token/s on Glitter Bridge and instantly gain access to all of our
        supported blockchains! Here you’ll also find details on how you can
        easily integrate with our bridge.
      </S.OverviewText>
    </S.Overview>
  );
};

export default OverviewInformation;
