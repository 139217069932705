import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from "react-router-dom";
import DeveloperToolsPage from "./views/DeveloperToolsPage";
import Home from "./views/Home";
import Layout from "./views/Layout";

const App = () => {
  return (
    <Router>
      <Layout>
        <Routes>
          <Route path="*" element={<Navigate to="/404" replace />} />
          <Route path="/404" element={<h1>Not found</h1>} />
          <Route path="/" element={<Home />} />
          <Route path="/developer-tools" element={<DeveloperToolsPage />} />
        </Routes>
      </Layout>
    </Router>
  );
};

export default App;
