import styled from "styled-components";

export const TokenManagment = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 138px;

  @media screen and (max-width: 950px) {
    margin-top: 78px;
  }

  @media screen and (max-width: 500px) {
    margin-top: 69px;
  }
`;

export const TokenManagmentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1378px;
  padding: 0 24px;
  padding-left: 0;

  @media screen and (max-width: 950px) {
    flex-direction: column;
    padding-left: 24px;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;

export const TokenManagmentImage = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  margin-left: 50px;

  @media screen and (max-width: 950px) {
    margin-left: 0;
  }
`;

export const TokenManagmentTextContainer = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  max-width: 566px;
  margin-left: 166px;

  @media screen and (max-width: 950px) {
    margin-left: 0;
    max-width: 100%;
    margin-top: 76px;
  }

  @media screen and (max-width: 500px) {
    align-items: center;
    margin-top: 55px;
  }
`;

export const TokenManagmentTitle = styled.h1`
  font-size: 48px;
  font-weight: 700;
  color: #f8efff;
  margin-top: 21px;

  @media screen and (max-width: 950px) {
    font-size: 32px;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
    width: 100%;
    font-size: 16px;
    margin-top: 9px;
  }
`;

export const TokenManagmentTitleMini = styled.h1`
  font-size: 24px;
  font-weight: 700;
  color: #28022e;

  @media screen and (max-width: 950px) {
    font-size: 14px;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
    width: 100%;
    font-size: 10px;
  }
`;

export const TokenManagmentText = styled.p`
  font-weight: 500;
  font-size: 24px;
  color: #28022e;
  margin-top: 17px;

  @media screen and (max-width: 950px) {
    font-size: 16px;
  }

  @media screen and (max-width: 500px) {
    text-align: center;
    width: 100%;
    font-size: 10px;
    margin-top: 11px;
  }
`;

export const TokenManagmentTextButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 450px;
  width: 100%;
  padding: 15px 30px;
  background: linear-gradient(133.83deg, #8d40cc 4.61%, #5cdcee 94.48%);
  margin-top: 53px;
  color: #fff;
  font-weight: 700;
  border-radius: 51px;
  opacity: 0.75;
  font-size: 24px;
  opacity: 0.5;

  @media screen and (max-width: 950px) {
    font-size: 17px;
    max-width: 350px;
    margin-top: 23px;
  }

  @media screen and (max-width: 500px) {
    font-size: 10px;
    padding: 6px 12px;
    max-width: 200px;
  }
`;

export const TokenManagmentRectangleOne = styled.img`
  position: absolute;
  top: -26px;
  right: -104px;
  @media screen and (max-width: 1400px) {
    width: 300px;
  }

  @media screen and (max-width: 950px) {
    width: 175px;
    right: -66px;
  }

  @media screen and (max-width: 440px) {
    display: none;
  }
`;

export const TokenManagmentRectangleTwo = styled.img`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: -52px;
  @media screen and (max-width: 1400px) {
    width: 450px;
    bottom: -40px;
  }

  @media screen and (max-width: 950px) {
    width: 300px;
  }

  @media screen and (max-width: 440px) {
    display: none;
  }
`;

export const TokenManagmentImageSrc = styled.img`
  position: relative;
  z-index: 5;

  @media screen and (max-width: 1400px) {
    width: 400px;
  }

  @media screen and (max-width: 950px) {
    width: 270px;
  }
`;
