import styled from "styled-components";

export const Layout = styled.div`
  min-height: 100vh;
  background: radial-gradient(
      50.42% 50.42% at 50% 39.26%,
      rgba(255, 0, 122, 0.27) 0%,
      rgba(255, 0, 122, 0) 100%
    ),
    radial-gradient(
      52.65% 26.04% at 52.65% 66.17%,
      rgba(136, 18, 157, 0.28) 0%,
      rgba(169, 38, 192, 0) 100%
    ),
    radial-gradient(
      61.53% 42.43% at 19.09% 38.43%,
      rgba(97, 198, 255, 0.28) 2.8%,
      rgba(105, 246, 255, 0.126) 100%
    ),
    radial-gradient(
      112.97% 83.27% at 104.67% 100.88%,
      rgba(197, 237, 255, 0.1344) 0%,
      rgba(196, 234, 255, 0.1316) 98.54%
    ),
    radial-gradient(
        78.88% 32.45% at 4.66% 101.63%,
        rgba(250, 11, 255, 0.1652) 0%,
        rgba(255, 68, 68, 0.1232) 100%
      )
      #c8c8c8;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
`;

export const Navbar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 34px 24px;
  padding-bottom: 0;
  position: relative;
  z-index: 5;
`;

export const Content = styled.div`
  flex: 1;
  overflow-x: hidden;
  position: relative;
  z-index: 10;
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.2),
      rgba(255, 255, 255, 0.2)
    ),
    radial-gradient(
      107.29% 106.81% at 65.81% 102.7%,
      rgba(191, 57, 254, 0.56) 6.84%,
      rgba(254, 57, 128, 0.5) 25.04%,
      rgba(63, 118, 225, 0.49) 47.95%,
      rgba(254, 57, 128, 0.5) 74.46%,
      rgba(125, 43, 189, 0.32) 89.93%
    );
  border-radius: 308px 308px 0px 0px;
  margin-top: 27px;

  @media screen and (max-width: 1220px) {
    border-radius: 40.7468px 40.7468px 0px 0px;
  }
`;
