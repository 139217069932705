import React from "react";
import DeveloperTools from "../DeveloperTools";
import BackButtons from "../BackButtons";
import OverviewSection from "../OverviewSection";
import Portal from "../Portal";
import TokenManagment from "../TokenManagment";
import Footer from "../../views/Footer";
import * as S from "./styles";

const Home = () => {
  return (
    <S.Home>
      <BackButtons />
      <OverviewSection />
      <Portal />
      <DeveloperTools />
      <TokenManagment />
      <Footer />
    </S.Home>
  );
};

export default Home;
