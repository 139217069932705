import React from "react";
import * as S from "./styles";
import TokenManagmentImage from "../../assets/images/portal.png";
import ReactangleOne from "../../assets/images/rectangle_1.png";
import ReactangleTwo from "../../assets/images/rectangle_2.png";

const TokenManagment = () => {
  return (
    <S.TokenManagment>
      <S.TokenManagmentContainer>
        <S.TokenManagmentImage>
          <S.TokenManagmentImageSrc src={TokenManagmentImage} alt="token" />
          <S.TokenManagmentRectangleOne
            src={ReactangleOne}
            alt="rectangle_one"
          />{" "}
          <S.TokenManagmentRectangleTwo
            src={ReactangleTwo}
            alt="rectangle_two"
          />
        </S.TokenManagmentImage>
        <S.TokenManagmentTextContainer>
          <S.TokenManagmentTitleMini>TOKENS</S.TokenManagmentTitleMini>
          <S.TokenManagmentTitle>TOKEN MANAGMENT</S.TokenManagmentTitle>
          <S.TokenManagmentText>
            Here you will be able to create and list your tokens, which will
            gain instant cross-chain status thanks to Glitter’s layer-0
            interoperability protocol!
          </S.TokenManagmentText>
          <S.TokenManagmentTextButton>
            TOKEN PORTAL COMING SOON
          </S.TokenManagmentTextButton>
        </S.TokenManagmentTextContainer>
      </S.TokenManagmentContainer>
    </S.TokenManagment>
  );
};

export default TokenManagment;
