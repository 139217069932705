import styled from "styled-components";

export const FooterSocials = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 103px;
  text-align: center;

  a {
    margin-right: 23px;

    &:last-child {
      margin-right: 0;
    }
  }

  path {
    fill: #532c7b;
  }

  @media screen and (max-width: 500px) {
    margin-top: 32px;
  }
`;

export const FooterSocialView = styled.div`
  text-align: center;
`;

export const FooterText = styled.p`
  margin-top: 26px;
  font-weight: 600;
  font-size: 23px;
  color: #ffffff;
  opacity: 0.6;

  @media screen and (max-width: 500px) {
    font-size: 13px;
  }
`;

export const FooterResponsive = styled.div``;
