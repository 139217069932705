import styled from "styled-components";

export const Circle = styled.div`
  position: absolute;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};
  background: ${({ background }) => background};
  top: ${({ top }) => `${top}px` || "none"};
  left: ${({ left }) => `${left}px` || "none"};
  right: ${({ right }) => `${right}px` || "none"};
  bottom: ${({ bottom }) => `${bottom}px` || "none"};
  border-radius: 50%;
`;
